import Vue from 'vue'

export const SettingsModule = {
  namespaced: true,

  state: () => ({
    title: '',
    description: '',
    currentLanguage: '',
    general: {
      period: null,
      startDate: null,
      startDateTimezone: null,
      endDate: null,
      countQuestions: null,
      languages: null,
      rangeType: null,
      questionsDistribution: null,
      computeType: null,
    },
    peopleReportAccess: [],
    people: [],
    groups: [],
    sendEntireCompany: false,
    sendAllLeaders: false,
    questions: [],
    categories: [],
  }),

  getters: {
    getSettings: state => state,
    getGeneral: state => state.general,
    getPeople: state => state.people,
    getGroups: state => state.groups,
    getSendEntireCompany: state => state.sendEntireCompany,
    getPeopleReportAccess: state => state.peopleReportAccess,
    getSendAllLeaders: state => state.sendAllLeaders,
    getCategories: state => state.categories,
    getLanguages: state => state.general.languages,
    getTitle: state => state.title,
    getDescription: state => state.description,
    getCurrentLanguage: state => state.currentLanguage,
    getQuestions: state => state.questions,
  },

  actions: {
    setGeneral({ commit }, payload) {
      commit('SET_GENERAL', payload)
    },
    setPeople({ commit }, payload) {
      commit('SET_PEOPLE', payload)
    },
    setGroups({ commit }, payload) {
      commit('SET_GROUPS', payload)
    },
    setSendEntireCompany({ commit }, payload) {
      commit('SET_SEND_ENTIRE_COMPANY', payload)
    },
    setPeopleReportAccess({ commit }, payload) {
      commit('SET_PEOPLE_REPORT_ACCESS', payload)
    },
    setSendAllLeaders({ commit }, payload) {
      commit('SET_SEND_ALL_LEADERS', payload)
    },
    setCategories({ commit }, payload) {
      commit('SET_CATEGORIES', payload)
    },
    setTitle({ commit }, payload) {
      commit('SET_TITLE', payload)
    },
    setDescription({ commit }, payload) {
      commit('SET_DESCRIPTION', payload)
    },
    setCurrentLanguage({ commit }, payload) {
      commit('SET_CURRENT_LANGUAGE', payload)
    },
    setQuestions({ commit }, payload) {
      commit('SET_QUESTIONS', payload)
    },
    reset({ commit }) {
      commit('RESET')
    },
  },

  mutations: {
    SET_GENERAL(state, payload) {
      Vue.set(state, 'general', payload)
    },
    SET_PEOPLE(state, payload) {
      Vue.set(state, 'people', payload)
    },
    SET_GROUPS(state, payload) {
      Vue.set(state, 'groups', payload)
    },
    SET_SEND_ENTIRE_COMPANY(state, payload) {
      Vue.set(state, 'sendEntireCompany', payload)
    },
    SET_PEOPLE_REPORT_ACCESS(state, payload) {
      Vue.set(state, 'peopleReportAccess', payload)
    },
    SET_SEND_ALL_LEADERS(state, payload) {
      Vue.set(state, 'sendAllLeaders', payload)
    },
    SET_CATEGORIES(state, payload) {
      Vue.set(state, 'categories', payload)
    },
    SET_TITLE(state, payload) {
      Vue.set(state, 'title', payload)
    },
    SET_DESCRIPTION(state, payload) {
      Vue.set(state, 'description', payload)
    },
    SET_CURRENT_LANGUAGE(state, payload) {
      Vue.set(state, 'currentLanguage', payload)
    },
    SET_QUESTIONS(state, payload) {
      Vue.set(state, 'questions', payload)
    },
    RESET(state) {
      state.title = ''
      state.description = ''
      state.currentLanguage = ''
      state.general = {
        period: null,
        startDate: null,
        startDateTimezone: null,
        endDate: null,
        countQuestions: null,
        languages: null,
        rangeType: null,
        questionsDistribution: null,
        computeType: null,
      }
      state.peopleReportAccess = []
      state.people = []
      state.groups = []
      state.sendEntireCompany = false
      state.sendAllLeaders = false
      state.questions = []
      state.categories = []
    },
  },
}
