import { constructParams, extractSignal } from '@/helpers/api'
import api from '@/services/http'

export async function createSurvey(data) {
  return (await api()).post('/custom-surveys', data)
}

export async function getSurveys(data) {
  const params = constructParams(
    [
      'limit',
      'offset',
      'sortOrder',
      'sortBy',
      'status',
      'createdBy',
      'startDate',
      'endDate',
      'title',
    ],
    data
  )

  return (await api()).get('/custom-surveys', {
    params,
  })
}

export async function getSurveyReportInfo(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID', {
    _params: {
      customSurveyID,
    },
  })
}

export async function getSurveyQuestions(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID/survey', {
    _params: {
      customSurveyID,
    },
  })
}

export async function saveSurvey({ id, data }) {
  return (await api()).post('/custom-surveys/:customSurveyID/survey', data, {
    _params: {
      customSurveyID: id,
    },
  })
}

export async function getGroups(customSurveyID) {
  return (await api()).get('/custom-surveys/:customSurveyID/groups/tree', {
    _params: {
      customSurveyID,
    },
  })
}

export async function getResults(
  customSurveyID,
  groupID,
  categoryID,
  demographicReportID
) {
  const params = constructParams(
    ['groupID', 'categoryID', 'demographicReportID'],
    {
      groupID,
      categoryID,
      demographicReportID,
    }
  )

  return (await api()).get('/custom-surveys/:customSurveyID/results', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getHistory(
  customSurveyID,
  groupID,
  viewType,
  categoryID,
  demographicReportID
) {
  const params = constructParams(
    ['groupID', 'viewType', 'categoryID', 'demographicReportID'],
    {
      groupID,
      viewType,
      categoryID,
      demographicReportID,
    }
  )

  return (await api()).get('/custom-surveys/:customSurveyID/results/history', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getResultsNPS(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/custom-surveys/:customSurveyID/results/nps', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getHistoryNPS(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/history/nps',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getParticipation(customSurveyID, groupID) {
  const params = constructParams(['groupID'], {
    groupID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/participations',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getParticipationHistory(customSurveyID, groupID) {
  const params = constructParams(['groupID'], {
    groupID,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/results/history/participations',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function getResultsExport(
  customSurveyID,
  groupID,
  demographicReportID
) {
  const params = constructParams(['groupID', 'demographicReportID'], {
    groupID,
    demographicReportID,
  })

  return (await api()).get('/custom-surveys/:customSurveyID/results/export', {
    _params: {
      customSurveyID,
    },
    params,
    responseType: 'blob',
  })
}

export async function getComparison(customSurveyID, groupIDs) {
  const body = {}

  if (groupIDs.length) {
    body.groupIDs = [...new Set(groupIDs)]
  }

  return (await api()).post(
    '/custom-surveys/:customSurveyID/results/groups-comparison',
    body,
    {
      _params: {
        customSurveyID,
      },
    }
  )
}

export async function getComparisonExport(customSurveyID, groupIDs) {
  const body = {}

  if (groupIDs.length) {
    body.groupIDs = groupIDs
  }

  return (await api()).post(
    '/custom-surveys/:customSurveyID/results/export/groups-comparison',
    body,
    {
      _params: {
        customSurveyID,
      },
      responseType: 'blob',
    }
  )
}

export async function getDemographicReports(customSurveyID, groupID, data) {
  const params = constructParams(['groupID', 'limit', 'offset'], {
    groupID,
    ...data,
  })

  return (await api()).get(
    '/custom-surveys/:customSurveyID/demographic-reports',
    {
      _params: {
        customSurveyID,
      },
      params,
    }
  )
}

export async function createDemographicReport(customSurveyID, data) {
  return (await api()).post(
    '/custom-surveys/:customSurveyID/demographic-reports',
    data,
    {
      _params: {
        customSurveyID,
      },
    }
  )
}

export async function deleteDemographicReport(customSurveyID, reportID) {
  return (await api()).delete(
    '/custom-surveys/:customSurveyID/demographic-reports/:reportID',
    {
      _params: {
        customSurveyID,
        reportID,
      },
    }
  )
}

export async function getOpinions(customSurveyID, data, groupID) {
  const params = constructParams(
    [
      'groupID',
      'limit',
      'offset',
      'sortOrder',
      'metricID',
      'questionID',
      'reply',
      'readed',
      'closed',
      'rated',
      'ratedScore',
      'feeling',
      'fromDate',
      'toDate',
      'tagsID',
      'sortBy',
      'period',
    ],
    { ...data, groupID }
  )

  return (await api()).get('/custom-surveys/:customSurveyID/opinions', {
    _params: {
      customSurveyID,
    },
    params,
  })
}

export async function getOpinionMessages(opinionID, customSurveyID) {
  const params = {}

  return (await api()).get(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/messages',
    {
      _params: {
        customSurveyID,
        opinionID,
      },
      params,
    }
  )
}

export async function getOpinionInfo(groupID, opinionID, customSurveyID) {
  const params = {}

  if (groupID) {
    params.groupID = groupID
  }

  return (await api()).get(
    '/custom-surveys/:customSurveyID/opinions/:opinionID',
    {
      _params: {
        customSurveyID,
        opinionID,
      },
      params,
    }
  )
}

export async function postOpinionMessage(customSurveyID, data) {
  return (await api()).post(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/messages',
    {
      message: data.message,
      isAnonymous: data.isAnonymous,
    },
    {
      _params: {
        customSurveyID,
        opinionID: data.opinionID,
      },
    }
  )
}

export async function putOpinionReading(customSurveyID, data) {
  const signal = extractSignal(data)

  return (await api()).put(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/reading',
    {},
    {
      _params: { customSurveyID, opinionID: data.opinionID },
      ...signal,
    }
  )
}

export async function deleteOpinionReading(customSurveyID, opinionID) {
  return (await api()).delete(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/reading',
    {
      _params: {
        customSurveyID,
        opinionID,
      },
    }
  )
}

export async function closeOpinion(customSurveyID, opinionID) {
  return (await api()).put(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/closing',
    {},
    {
      _params: {
        customSurveyID,
        opinionID,
      },
    }
  )
}

export async function getMyOpinions(customSurveyID, data) {
  const params = {
    limit: data.limit,
    offset: data.offset,
    readed: data.readed,
    sortOrder: data.sortOrder,
  }

  return (await api()).get('/custom-surveys/:customSurveyID/opinions/me', {
    _params: { customSurveyID },
    params,
  })
}

export async function putOpinionRating(customSurveyID, data) {
  return (await api()).put(
    '/custom-surveys/:customSurveyID/opinions/:opinionID/rating/:ratingID',
    {},
    {
      _params: {
        customSurveyID,
        opinionID: data.opinionID,
        ratingID: data.ratingID,
      },
    }
  )
}
