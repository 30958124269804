<template>
  <div>
    <DemographicReports
      v-if="_canListReports"
      data-test-results-filters-demographic-reports
      class="menu-item"
      :is-mini="mini"
      :reports="reports"
      :report-selected="reportSelectedID"
      :loading="loading.reports"
      :display-show-more-action="_showMoreAction"
      @on-show-more-click="handleShowMoreReports"
      @on-delete-click="handleReportDelete"
      @on-refresh-click="handleRefreshReports"
      @on-report-click="handleReportSelect"
    ></DemographicReports>
    <SmartAttributes
      v-if="_canCreateReport"
      ref="smartAttributes"
      data-test-results-filters-smart-attributes
      class="menu-item"
      :is-mini="mini"
      :loading="loading.attributes"
      :attributes="attributes"
      @on-generate-report="handleGenerateReport"
    ></SmartAttributes>
  </div>
</template>

<script>
import { handleAlertBus } from '@/helpers/alerts'
import DemographicReports from './Partials/DemographicReports/DemographicReports.vue'
import SmartAttributes from './Partials/SmartAttributes/SmartAttributes.vue'
import {
  createDemographicReport,
  deleteDemographicReport,
  getDemographicReports,
} from '@/services/survey'
import { mapActions, mapGetters } from 'vuex'
import { handleCustomAlertError } from '@/helpers/data/errors'
import { getPeopleAttributes } from '@/services/management'
import * as _permissions from '@/helpers/ability/permissions'

export default {
  name: 'ResultsFilters',
  components: {
    DemographicReports,
    SmartAttributes,
  },
  props: {
    mini: {
      type: Boolean,
    },
  },
  data() {
    return {
      total: 0,
      loading: {
        reports: false,
        attributes: false,
      },
      reportFilters: {
        offset: 0,
        limit: 5,
      },
      reportSelectedID: null,
      reports: [],
      attributes: [],
    }
  },
  computed: {
    ...mapGetters({
      _surveyID: 'Report/getSurveyID',
      _groupID: 'Report/getGroupID',
    }),
    _showMoreAction() {
      return this.reports.length < this.total
    },
    _canCreateReport() {
      return this.$can(
        'access',
        _permissions.custom_survey_results_demographic_reports_create
      )
    },
    _canListReports() {
      return this.$can(
        'access',
        _permissions.custom_survey_results_demographic_reports
      )
    },
  },
  watch: {
    _groupID() {
      this.resetFetchReports()
    },
  },
  mounted() {
    this.handleFetchReports()
    this.handleFetchAttributes()
  },
  methods: {
    ...mapActions({
      setDemographicReportID: 'Report/setDemographicReportID',
    }),
    handleFetchReports() {
      if (!this._canListReports) {
        return
      }
      this.loading.reports = true
      const customSurveyID = this._surveyID || this.$route.params.customSurveyID
      getDemographicReports(customSurveyID, this._groupID, this.reportFilters)
        .then(response => {
          this.reports = this.reports.concat(response.data || [])
          this.total = response.headers['x-count']
        })
        .catch(error => {
          this.reports = []
          this.total = 0
          handleCustomAlertError(error)
        })
        .finally(() => {
          this.loading.reports = false
        })
    },
    handleFetchAttributes() {
      if (!this._canCreateReport) {
        return
      }
      this.loading.attributes = true
      getPeopleAttributes()
        .then(response => {
          // @TODO: remoção temporária de atributos NPS
          const ids = ['63d19077b1a86a00293f84a2', '63d1909bb1a86a00293f84a7']
          this.attributes = response.data.filter(({ id }) => !ids.includes(id))
        })
        .catch(error => {
          this.attributes = []
          handleCustomAlertError(error)
        })
        .finally(() => {
          this.loading.attributes = false
        })
    },
    handleReportDelete(reportID) {
      const reportIndex = this.reports.indexOf(
        this.reports.find(report => report.id === reportID)
      )
      const report = this.reports[reportIndex]
      report.index = reportIndex
      this.$root.$emit('results:modal:open', {
        type: 'deleteReport',
        data: report,
        callback: this.handleConfirmReportDelete,
      })
    },
    handleConfirmReportDelete(reportID) {
      this.loading.reports = true
      const customSurveyID = this._surveyID || this.$route.params.customSurveyID
      deleteDemographicReport(customSurveyID, reportID)
        .then(() => {
          this.resetFetchReports()
        })
        .catch(error => {
          handleCustomAlertError(error)
        })
        .finally(() => {
          this.loading.reports = false
        })
    },
    handleReportSelect(report) {
      this.reportSelectedID = !report.unselect ? report.id : null
      this.setDemographicReportID(this.reportSelectedID)
    },
    handleGenerateReport(data) {
      if (!this._canCreateReport) {
        return
      }
      if (!data) {
        handleAlertBus(
          this.$t('demographicReports.selectSmartAttribute'),
          null,
          'warning'
        )
        return
      }
      this.loading.reports = true
      const customSurveyID = this._surveyID || this.$route.params.customSurveyID
      const payload = {
        attributes: data,
        groupID: this._groupID,
      }
      createDemographicReport(customSurveyID, payload)
        .then(() => {
          this.resetFetchReports()
        })
        .catch(error => {
          this.showAlertError(error)
        })
        .finally(() => {
          this.loading.reports = false
        })
    },
    showAlertError(error) {
      const data = error.response.data
      const message = data.message
      const invalidAttributes = data?.data?.invalidAttributes || []
      const alertData = this.createValidationErrorMessage(
        message,
        invalidAttributes
      )
      this.$root.$emit('display-alert', alertData)
    },
    createValidationErrorMessage(message, invalidAttributes) {
      let attributesMessage = invalidAttributes.length > 0 ? ': ' : ''

      invalidAttributes.forEach((attribute, index) => {
        const isTheLastItem = invalidAttributes.length === index + 1
        const separator = isTheLastItem ? '.' : ', '
        const attributeValue = attribute?.value ? ` (${attribute.value}) ` : ''
        attributesMessage += `${attribute.key}${attributeValue}${separator}`
      })

      const description = `
        ${message}<b>${attributesMessage}</b>
      `
      return {
        title: this.$t('demographicReports.errorValidationTitle'),
        description,
        type: 'error',
        timeout: 10000,
        hasIconTitle: true,
      }
    },
    async handleRefreshReports() {
      await this.resetFetchReports()
      this.reportSelectedID = this.reports.find(
        report => report.id === this.reportSelectedID
      )?.id
      if (!this.reportSelectedID) {
        this.setDemographicReportID(null)
      }
    },
    handleShowMoreReports() {
      this.reportFilters.offset += this.reportFilters.limit
      this.handleFetchReports()
    },
    resetFetchReports() {
      this.reports = []
      this.reportFilters.offset = 0
      this.handleFetchReports()
    },
  },
}
</script>

<style scoped lang="scss" src="./style.scss" />
